import { useRouter } from "next/router";

import { Box, MenuItem, Stack, IconButton, Link as MuiLink, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Iconify } from "../../atoms/Iconify";
import { usePopover } from "../../hooks/use-popover";
import { useTranslation } from "../../hooks/use-translation";
import { MenuPopover } from "../../atoms/MenuPopover/MenuPopover";
import { useMemo } from "react";
import { IconifyIconProps } from "@iconify/react";

// ----------------------------------------------------------------------

type LanguagePopoverProps = {
  isOffset: boolean;
  names?: {
    az?: string;
    ru?: string;
    en?: string;
  };
  dataTestIds?: {
    button?: string;
    menuItem?: {
      az: string;
      ru: string;
      en: string;
    };
  };
  hideOnMobile?: boolean;
};

export const LanguagePopover = ({ isOffset, names, dataTestIds, hideOnMobile = false }: LanguagePopoverProps) => {
  const popover = usePopover();
  const t = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const langIcon = useMemo(() => {
    if (router.locale) {
      switch (router.locale) {
        case "ru": {
          return "twemoji:flag-russia";
        }
        case "az": {
          return "twemoji:flag-azerbaijan";
        }
        case "en": {
          return "twemoji:flag-united-kingdom";
        }
        default:
          return "tabler:world";
      }
    }
  }, [router.locale]);

  if (hideOnMobile && isMobile) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          display: {
            md: "inline-flex",
            gap: "8px",
          },
          paddingY: "6px",
          paddingX: "12px",
          ml: 1,
          height: 32,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "35px",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
          },
          fontSize: "14px",
          fontWeight: 600,
          zIndex: 1,
        }}
        data-testid={dataTestIds?.button}
      >
        <Iconify icon={langIcon as IconifyIconProps["icon"]} />
        <Typography variant="body2" fontWeight={600}>
          {t(`${router.locale}-short`)}
        </Typography>
      </IconButton>

      <MenuPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          width: 200,
          mt: 1,
        }}
        arrow={"top-center"}
      >
        <Stack spacing={0.75}>
          <MuiLink
            href={`/az${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.az}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-azerbaijan"} />
              <Box
                sx={{
                  fontWeight: 600,
                }}
              >
                {names?.az ?? t("az")}
              </Box>
            </MenuItem>
          </MuiLink>
          <MuiLink
            href={`/ru${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.ru}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-russia"} />
              <Box
                sx={{
                  fontWeight: 600,
                }}
              >
                {names?.ru ?? t("ru")}
              </Box>
            </MenuItem>
          </MuiLink>
          <MuiLink
            href={`/en${router.asPath}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.en}
          >
            <MenuItem selected={false}>
              <Iconify icon={"twemoji:flag-united-kingdom"} />
              <Box
                sx={{
                  fontWeight: 600,
                }}
              >
                {names?.en ?? t("en")}
              </Box>
            </MenuItem>
          </MuiLink>
        </Stack>
      </MenuPopover>
    </>
  );
};
