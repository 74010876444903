import { forwardRef } from "react";

import { Box, Tooltip, ListItemText, Link as MuiLink } from "@mui/material";
import { Iconify } from "../../../../fe-ui/atoms/Iconify";
import { NavItemProps } from "../types";
import { StyledItem, StyledIcon } from "./styles";
import { Link } from "../../../../fe-ui/atoms/Link/Link";

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(function NavItemInner(
  { item, depth, open, active, isExternalLink, ...other },
  ref
) {
  const {
    title,
    path,
    icon,
    info,
    children,
    disabled,
    caption
    //roles
  } = item;
  const subItem = depth !== 1;
  const renderContent = (
    <StyledItem ref={ref} open={open} depth={depth} active={active} disabled={disabled} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      <ListItemText
        primary={`${title}`}
        primaryTypographyProps={{
          noWrap: true,
          component: "span",
          variant: active ? "subtitle2" : "body2"
        }}
      />

      {info && (
        <Box component={"span"} sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {caption && (
        <Tooltip title={`${caption}`} arrow>
          <Box component={"span"} sx={{ ml: 0.5, lineHeight: 0 }}>
            <Iconify icon={"eva:info-outline"} width={16} />
          </Box>
        </Tooltip>
      )}

      {!!children && (
        <Iconify
          icon={subItem ? "eva:chevron-right-fill" : "eva:chevron-down-fill"}
          width={16}
          sx={{ ml: 0.5, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <MuiLink href={path} target={"_blank"} rel={"noopener"} underline={"none"}>
          {renderContent}
        </MuiLink>
      );

    // Default
    return (
      <Link href={path} underline={"none"}>
        {renderContent}
      </Link>
    );
  };

  return renderItem();
});

export default NavItem;
