import { forwardRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box } from "@mui/material";
import { ImageProps } from "./types";
import { getRatio } from "./get-ratio";

export const Image = forwardRef<HTMLSpanElement, ImageProps>(function Image(
  { ratio, disabledEffect = false, effect = "blur", sx, ...other },
  ref
) {
  const content = (
    <Box
      component={LazyLoadImage}
      wrapperClassName={"wrapper"}
      effect={disabledEffect ? undefined : effect}
      // placeholderSrc={
      //   disabledEffect ? "/assets/transparent.png" : "/assets/placeholder.svg"
      // }
      sx={{ width: 1, height: 1, objectFit: "cover" }}
      {...other}
    />
  );

  if (ratio) {
    return (
      <Box
        ref={ref}
        component={"span"}
        sx={{
          width: 1,
          lineHeight: 1,
          display: "block",
          overflow: "hidden",
          position: "relative",
          height: "100%",
          pt: getRatio(ratio),
          "& .wrapper": {
            height: "100%",
            top: 0,
            left: 0,
            width: 1,
            // height: 1,
            position: "absolute",
            backgroundPosition: "top center",
            backgroundSize: "cover !important",
          },
          ...sx,
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Box
      ref={ref}
      component={"span"}
      sx={{
        lineHeight: 1,
        display: "block",
        overflow: "hidden",
        position: "relative",
        height: "100%",
        "& .wrapper": {
          width: 1,
          height: 1,
          backgroundSize: "cover !important",
        },
        ...sx,
      }}
    >
      {content}
    </Box>
  );
});

export default Image;
