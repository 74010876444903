import NextLink from "next/link";
import MuiLink, { LinkProps } from "@mui/material/Link";
import { useRouter } from "next/router";
import { ForwardedRef, forwardRef } from "react";

const LinkBehaviour = forwardRef(function LinkBehaviour(
  props: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const { locale: _locale } = useRouter();
  const locale =
    typeof window !== "undefined" ? window["__LOCALE__"] || "ru" : _locale;
  // @ts-ignore
  return <NextLink locale={locale} ref={ref} {...props} />;
});

export const Link = (props: LinkProps) => {
  return <MuiLink component={LinkBehaviour} {...props} />;
};
