// @mui
import { Box, MenuItem, Stack, IconButton, Link as MuiLink } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuPopover from "../menu-popover";
import { Iconify } from "../../../fe-ui/atoms/Iconify";
import { usePopover } from "../../../fe-ui/hooks/use-popover";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";

// ----------------------------------------------------------------------

type Props = {
  isOffset: boolean;
  dataTestIds?: {
    button?: string;
    menuItem?: {
      tel: string;
      tg: string;
      wa: string;
    };
  };
  withTelegram: boolean;
};

export const ContactsSelector = ({ isOffset, dataTestIds, withTelegram }: Props) => {
  const popover = usePopover();
  const theme = useTheme();
  const t = useTranslation();

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        data-ym={"HEADER_CONTACT"}
        sx={{
          display: {
            md: "inline-flex",
          },
          ml: 1,
          width: 30,
          height: 30,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "15px",
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
          },
          zIndex: 1,
          ...(isOffset && {
            color: "text.primary",
          }),
        }}
        data-testid={dataTestIds?.button}
      >
        <Iconify color="text.primary" icon="mingcute:phone-call-line" />
      </IconButton>

      <MenuPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, mt: 1 }}>
        <Stack spacing={0.75}>
          <MuiLink
            href={`tel:${t("contacts_phone")}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.tel}
            data-ym={"HEADER_CONTACT_PHONE"}
          >
            <MenuItem selected={false}>
              <Iconify icon="mingcute:phone-call-line" />
              <Box>{t("contacts_phone_display")}</Box>
            </MenuItem>
          </MuiLink>
          {withTelegram ? (
            <MuiLink
              href={`https://t.me/${t("contacts_phone")}`}
              color={theme.palette.text.primary}
              data-testid={dataTestIds?.menuItem?.tg}
              data-ym={"HEADER_CONTACT_TELEGRAM"}
            >
              <MenuItem selected={false}>
                <Iconify icon="mingcute:telegram-line" />
                <Box>{"Telegram"}</Box>
              </MenuItem>
            </MuiLink>
          ) : null}
          <MuiLink
            href={`https://wa.me/${t("contacts_phone")}`}
            color={theme.palette.text.primary}
            data-testid={dataTestIds?.menuItem?.wa}
            data-ym={"HEADER_CONTACT_WA"}
          >
            <MenuItem selected={false}>
              <Iconify icon="mingcute:whatsapp-line" />
              <Box>{"WhatsApp"}</Box>
            </MenuItem>
          </MuiLink>
        </Stack>
      </MenuPopover>
    </>
  );
};
